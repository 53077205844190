<template>
  <div class="mian">
    <div class="main-left">
      <div class="state-box">
        <div class="img-box" v-if="type === 1">
          <img class="img" src="@/assets/images/reward/hd.png" />
          <span>完成</span>
          <img class="shengying" src="@/assets/images/reward/shengying.png" />
          <img class="iocn-img" src="@/assets/images/reward/iocn-dui.png" />
        </div>
        <div class="img-box" v-if="type === 2">
          <img class="img" src="@/assets/images/reward/bzb.png" />
          <span>被甄别</span>
          <img class="shengying" src="@/assets/images/reward/shengying.png" />
          <img class="iocn-img" src="@/assets/images/reward/chahao.png" />
        </div>
        <div class="img-box" v-if="type === 3">
          <img class="img" src="@/assets/images/reward/pem.png" />
          <span>配额满</span>
          <img class="shengying" src="@/assets/images/reward/shengying.png" />
          <img class="iocn-img" src="@/assets/images/reward/jinggao.png" />
        </div>

        <div class="border-box" v-if="type === 1">
          您已成功完成! <span v-if="goldCoin">金币</span
          ><span class="no-bold" v-if="goldCoin">+{{ goldCoin }}</span>
        </div>
        <div class="border-box" v-if="type === 2">
          很遗憾，您被甄别了！<span v-if="goldCoin">金币</span
          ><span class="no-bold" v-if="goldCoin">+{{ goldCoin }}</span>
        </div>
        <div class="border-box" v-if="type === 3">
          很遗憾，配额满了！<span v-if="goldCoin">金币</span
          ><span class="no-bold" v-if="goldCoin">+{{ goldCoin }}</span>
        </div>
        <div class="tips">
          <span v-if="type === 1">
            根据您的作答信息，您已经完成了全部作答问卷。
            感谢您的积极参与！</span
          >
          <span v-if="type === 2">
            根据您的作答信息，已经被甄别了。 感谢您的积极参与！</span
          >
          <span v-if="type === 3">
            根据您的作答信息，已经配额满了。 感谢您的积极参与！</span
          >
        </div>
        <div class="line"></div>
        <div class="title">您目前拥有{{ prizeOpportunity }}张抽奖卡</div>
        <div class="dt-box">
          <div class="cjk-box">
            <span class="cjk-name">抽奖卡X{{ prizeOpportunity }}</span>
            <img src="@/assets/images/reward/cjk.png" />
          </div>
          <div class="dt-btn" @click="goUrl('/prize-draw')">去抽奖</div>
        </div>
        <div class="title">更多奖励等你领取</div>
        <div class="hb-box">
          <img src="@/assets/images/reward/jl.png" />
          <div class="hb-title" v-if="isAllGift">
            您已经可以兑换{{ giftName }}！
          </div>
          <div class="hb-title" v-if="difference">
            还差<span>{{ difference }}金币</span>可兑换{{ giftName }}！
          </div>
        </div>
      </div>
    </div>
    <div class="main-right">
      <div class="right-box">
        <div class="box-top">
          <img src="@/assets/images/reward/tj.png" />
          <div class="top-right">
            <div>为你推荐</div>
            <div class="border">更多问卷等你来答</div>
          </div>
        </div>
        <div class="box-bottom" @click="goUrl('/tasks')">去答题</div>
      </div>
      <div class="right-box">
        <div class="box-top">
          <img src="@/assets/images/reward/xyz.png" />
          <div class="top-right">
            <div>为你推荐</div>
            <div class="border">增加信用值</div>
          </div>
        </div>
        <div class="box-bottom" @click="goUrl('/tasks')">去答题</div>
      </div>
      <div class="right-box">
        <div class="box-top">
          <img src="@/assets/images/reward/yqhy.png" />
          <div class="top-right">
            <div>为你推荐</div>
            <div class="border">推荐好友得金币</div>
          </div>
        </div>
        <div class="box-bottom" @click="goUrl('/center/recommend')">去推荐</div>
      </div>
    </div>

    <div class="zhezhao" v-if="fragmentShow"></div>

    <div class="jl-box" v-if="fragmentShow && fragmentUrl">
      <div class="jlt-box">
        <img class="jltc" src="@/assets/images/reward/jltc.png" />
        <img class="lv_jltc" :src="fragmentUrl" />
        <div class="wenben" @click="goUrl('/tasks')">继续点亮</div>
        <img
          class="close"
          src="@/assets/images/reward/close.png"
          @click="fragmentShow = false"
        />
      </div>
    </div>
  </div>
</template>
<script>
import { levelList } from "@/utlis/levelList";
export default {
  data() {
    return {
      type: 2,
      balance: 0,
      giftsList: [],
      difference: null,
      giftName: "",
      isAllGift: false,
      prizeOpportunity: 0,
      fragmentShow: false, //碎片显示
      fragmentUrl: null,
      goldCoin: null
    };
  },
  methods: {
    goUrl(path) {
      this.$router.push(path);
    },
    //过滤数组，返回符合条件的
    filterArray(arr, key, value) {
      // 使用Array.prototype.filter方法来筛选数组
      // 使用Array.prototype.some方法来检查对象中是否有满足条件的属性
      return arr.filter(obj => Object.values(obj).some(val => val === value));
    },

    //计算差值
    countDifference(list, index, number) {
      console.log(index, list.length);
      if (index === list.length) {
        this.isAllGift = true;
        this.giftName = list[index - 1].name;
        console.log("你可以兑换所有奖励");
        return;
      }

      if (list[index].displayPoints <= number) {
        //还可以兑换更多
        index++;
        this.countDifference(list, index, number);
      } else {
        //这就是你的极限了
        this.giftName = list[index].name;
        this.difference = list[index].displayPoints - number;
        console.log("你可以兑换此红包", list[index]);
      }
    },
    async getGifts() {
      const res = await this.$http.get(`/api/v1/Gifts`, {
        params: {
          page: 1,
          size: 100
        }
      });
      if (res.status === 200) {
        this.giftsList = this.filterArray(
          res.data.list,
          "category",
          "微信红包"
        );
        console.log("giftsList", this.giftsList);
      }
    },

    // 抽奖机会
    async getLatestChances() {
      const data = await this.$http.get(
        "/api/v1/Lottery/Chance/Stats?used=false"
      );
      let response = JSON.parse(data.request.response);
      if (response.status === 200) {
        this.prizeOpportunity = response.response;
      }
    }
  },
  async created() {
    // 获取携带参数

    if (this.$route.query) {
      if (this.$route.query.pi) {
        this.goldCoin = parseInt(this.$route.query.pi);
      }

      if (this.$route.query.jpid && this.$route.query.coord) {
        console.log("this.$route.query", this.$route.query);
        const coord = this.$route.query.coord.split(",");
        const fragmentName =
          "lv" + this.$route.query.jpid + "_" + coord[0] + "_" + coord[1];

        this.fragmentUrl = levelList[`${fragmentName}`];
        this.fragmentShow = true;
      }
      switch (this.$route.query.us) {
        case "s":
          // 条件不符合
          this.type = 2;
          break;
        case "q":
          // 配额满
          this.type = 3;
          break;
        case "c":
          // 成功完成
          this.type = 1;
          break;
      }
    }

    //用户id
    const userid = localStorage.getItem("userid");
    //获取用户金币
    const res = await this.$http.get("/api/v1/Accounts/" + userid);
    if (res.status === 200 && res.data.id) {
      this.balance = res.data.balance;
    }
    //获取可兑换列表
    await this.getGifts();
    await this.getLatestChances();

    //计算差值
    this.countDifference(this.giftsList, 0, this.balance);
  }
};
</script>
<style lang="scss" scoped>
.mian {
  background-color: #f8f8f8;
  display: flex;
  justify-content: center;
  padding: 20px 0 35px 0;
  .main-left {
    width: 529px;
    height: 655px;
    background: #ffffff;
    border-radius: 8px 8px 8px 8px;
    .img-box {
      width: 160px;
      height: 103px;
      position: relative;
      left: 50%;
      transform: translateX(-50%);
      margin-top: 42px;
      .img {
        width: 100%;
        height: 100%;
      }
      span {
        position: absolute;
        left: -24px;
        top: -16px;
        display: inline-block;
        width: 47px;
        height: 17px;
        background: #ffa626;
        border-radius: 3px 3px 3px 3px;
        text-align: center;
        color: #fff;
        font-size: 9px;
        line-height: 17px;
      }
      .shengying {
        position: absolute;
        top: -8px;
        right: 55px;
        width: 14px;
        height: 15px;
        animation: fadeIn 1s infinite;
      }
      .iocn-img {
        position: absolute;
        top: 40px;
        left: -10px;
        width: 26px;
        height: 26px;
        animation: rotate 2s infinite;
      }
    }
    .border-box {
      color: #232323;
      font-size: 15px;
      font-weight: bolder;
      text-align: center;
      margin-top: 24px;
      span {
        color: #ff9232;
        margin-left: 4px;
      }
      .no-bold {
        font-size: 14px;
        font-weight: normal;
        margin-left: 4px;
      }
    }
    .tips {
      color: #232323;
      font-size: 12px;
      text-align: center;
      width: 264px;
      position: relative;
      left: 50%;
      transform: translateX(-50%);
      margin-top: 18px;
    }
    .line {
      width: 435px;
      height: 0px;
      border: 1px solid #efefef;
      position: relative;
      left: 50%;
      transform: translateX(-50%);
      margin-top: 25px;
    }
    .title {
      font-size: 14px;
      color: #232323;
      margin-left: 47px;
      margin-top: 25px;
    }
    .dt-box {
      width: 435px;
      height: 130px;
      background: rgba(255, 206, 43, 0.1);
      border-radius: 10px 10px 10px 10px;
      position: relative;
      left: 50%;
      transform: translateX(-50%);
      margin-top: 21px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 17px;
      .cjk-box {
        position: relative;
        .cjk-name {
          position: absolute;
          color: #ffffff;
          font-size: 13px;
          left: 54px;
          top: 26px;
          z-index: 1;
        }
      }
      img {
        width: 190px;
        height: 90px;
        margin-left: 38px;
      }
      .dt-btn {
        width: 93px;
        height: 29px;
        background: #ffa220;
        line-height: 29px;
        text-align: center;
        border-radius: 14.5px;
        color: #fff;
        font-size: 14px;
        margin-right: 32px;
        cursor: pointer;
      }
    }
    .hb-box {
      display: flex;
      align-items: center;
      width: 435px;
      height: 69px;
      border-radius: 8px 8px 8px 8px;
      border: 1px solid rgba(163, 163, 163, 0.1);
      position: relative;
      left: 50%;
      transform: translateX(-50%);
      margin-top: 30px;
      img {
        width: 51px;
        height: 41px;
        margin-left: 27px;
      }
      .hb-title {
        color: #232323;
        font-size: 14px;
        margin-left: 30px;
        span {
          color: #ff3636;
        }
      }
    }
  }
  .main-right {
    margin-left: 16px;
    width: 358px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    .right-box {
      width: 100%;
      height: 206px;
      background: #ffffff;
      border-radius: 8px 8px 8px 8px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      .box-top {
        display: flex;
        align-items: center;
        color: #232323;
        img {
          width: 63px;
          height: 60px;
        }
        .top-right {
          display: flex;
          flex-direction: column;
          justify-content: center;
          font-size: 14px;
          margin-left: 33px;
          .border {
            font-weight: bolder;
            margin-top: 12px;
          }
        }
      }
      .box-bottom {
        width: 216px;
        height: 34px;
        background: #ffa220;
        line-height: 34px;
        border-radius: 17px;
        text-align: center;
        font-size: 14px;
        color: #fff;
        margin-top: 26px;
        cursor: pointer;
      }
    }
  }
}
.zhezhao {
  position: fixed;
  width: 100vw;
  height: 100vh;
  z-index: 1000000;
  background-color: rgba(0, 0, 0, 0.8);
  bottom: 0;
}
.jl-box {
  position: fixed;
  z-index: 1000001;
  width: 346px;
  height: 396px;
  top: 40%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
  display: flex;
  align-items: center;
  justify-content: center;
  .jlt-box {
    width: 346px;
    height: 396px;
    position: relative;
    animation: grow 1s;
    animation-fill-mode: forwards; /* 保留动画结束时的状态 */

    .jltc {
      width: 346px;
      height: 396px;
    }
    .lv_jltc {
      // width: 100px;
      height: 118px;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translateY(-40%) translateX(-50%);
    }
    .wenben {
      width: 103px;
      height: 37px;
      background: linear-gradient(180deg, #ffcb2d 0%, #ff8618 100%);
      border-radius: 19px 19px 19px 19px;
      border: 2px solid #ffffff;
      color: #fff;
      text-align: center;
      line-height: 37px;
      position: absolute;
      bottom: -40px;
      left: 50%;
      transform: translateX(-50%);
      cursor: pointer;
    }
  }

  .close {
    position: absolute;
    right: -24px;
    top: -12px;
    width: 24px;
    height: 24px;
    cursor: pointer;
  }
}
@keyframes fadeIn {
  0% {
    transform: scale(0);
  }
  50% {
    transform: scale(1.1);
  }
  100% {
    transform: scale(1); /* 2倍大小 */
  }
}
@keyframes rotate {
  20%,
  40%,
  60%,
  80%,
  100% {
    transform: rotate(0);
  }
  25% {
    transform: rotate(-15deg);
  }
  50% {
    transform: rotate(15deg);
  }
  75% {
    transform: rotate(-15deg);
  }
}
@keyframes grow {
  0% {
    transform: scale(0);
  }
  50% {
    transform: scale(1.1);
  }
  100% {
    transform: scale(1); /* 2倍大小 */
  }
}
</style>
